import type { JamFormContainerStepperSectionConfig } from 'components/JamFormContainer.vue';

import { stepperSectionsApplication } from './useJamFormStepApplication';
import { stepperSectionsContent } from './useJamFormStepContent';
import { stepperSectionsData } from './useJamFormStepData';
import { useJamValidation } from './useJamValidation';

export const useJamSteps = (
    companyId?: string,
    jobAdId?: string,
    stepData?: { [key: string]: any },
    jobData?: { [key: string]: any },
) => {
    const { isInternalUser } = useUser();

    const navigationPath = (step: string) => {
        return isInternalUser.value
            ? `/p/internal/companies/${companyId}/jobs/${jobAdId}/${step}`
            : `/p/jobs/${jobAdId}/${step}`;
    };

    const { hasError } = useJamValidation('jam-stepper');

    const isCompleted = (fields: string[]) => {
        for (const field of fields) {
            if (stepData && stepData[field] && stepData[field]?.length === 0) {
                return false;
            } else if (hasError(field)) {
                return false;
            }
        }
        return true;
    };

    const mapStep = (config: JamFormContainerStepperSectionConfig) => {
        const fieldsToMap: string[] = [];

        if (config?.optional) {
            let completed = false;

            config?.fields.forEach((field) => {
                // Ensure validation for employmentRelationship fields
                if (field.includes('employmentRelationship')) {
                    completed = true;
                    fieldsToMap.push(field);
                }

                if (!field || !stepData || !stepData[field]) return;

                const currentValue = stepData[field];

                // If current value is dirty (non-empty string or non-empty array), set completed to true
                if (typeof currentValue === 'string' && currentValue !== '') {
                    completed = true;
                    fieldsToMap.push(field);
                } else if (
                    Array.isArray(currentValue) &&
                    currentValue.length > 0
                ) {
                    const hasNonEmptyItems = currentValue.some((item) => {
                        return Object.values(item || {}).some(
                            (value) =>
                                typeof value === 'string' &&
                                value.trim() !== '',
                        );
                    });

                    if (hasNonEmptyItems) {
                        completed = true;
                        fieldsToMap.push(field);
                    }
                }
            });

            // On a new job (no jobData), show steps which should be visible initially as incomplete
            if (!jobData && config.initiallyVisible && !completed) {
                return {
                    completed: false,
                    name: config.title,
                };
            }

            // Return null for optional items that are not completed
            if (!completed) {
                return null;
            }
        }

        return {
            completed: config?.optional
                ? isCompleted(fieldsToMap)
                : isCompleted(config.fields),
            name: config.title,
        };
    };

    const steps = [
        {
            active: false,
            children: stepperSectionsData
                .map((item) => mapStep(item))
                .filter((item) => item !== null),
            collapsed: true,
            completed: false,
            key: 'data',
            locked: false,
            name: 'Daten',
            path: isInternalUser.value
                ? `/p/internal/companies/${companyId}/jobs/${jobAdId}/data`
                : jobAdId
                  ? `/p/jobs/${jobAdId}/data`
                  : '/p/jobs/create',
            visible: true,
        },
        {
            active: false,
            children: stepperSectionsContent
                .map((item) => mapStep(item))
                .filter((item) => item !== null),
            collapsed: true,
            completed: false,
            key: 'content',
            locked: false,
            name: 'Inhalt',
            path: navigationPath('content'),
            visible: true,
        },
        {
            active: false,
            children: stepperSectionsApplication
                .map((item) => mapStep(item))
                .filter((item) => item !== null),
            collapsed: true,
            completed: false,
            key: 'application',
            locked: false,
            name: 'Bewerbung',
            path: navigationPath('application'),
            visible: true,
        },
        {
            active: false,
            children: [],
            collapsed: true,
            completed: false,
            key: 'design',
            locked: false,
            name: 'Design',
            path: navigationPath('design'),
            visible: true,
        },
        {
            active: false,
            children: [],
            collapsed: true,
            completed: false,
            key: 'summary',
            locked: false,
            name: 'Zusammenfassung',
            path: navigationPath('summary'),
            visible: true,
        },
        {
            active: false,
            children: [],
            collapsed: true,
            completed: false,
            key: 'publish',
            locked: false,
            name: 'Inserat veröffentlichen',
            path: navigationPath('publish'),
            visible:
                stepData?.meta?.status !== 'ACTIVE' &&
                stepData?.meta?.status !== 'INACTIVE' &&
                stepData?.meta?.status !== 'SCHEDULED',
        },
    ];

    const stepsOrder = steps.map((step) => step.key);

    return { steps, stepsOrder };
};
